import React from 'react';
import { Row, Col } from 'reactstrap';

const XyAxis = (props) => {
    const { candidateName, minMax, distribution, label } = props;
    let [min, max] = minMax;
    let value = props.value || min;
    value = value < min ? min : (value > max ? max : value);
    let matrixPosition = 1;
    for (const [distMin, distMax] of distribution) {
        if (distMin <= value && value <= distMax) {
            break;
        }
        matrixPosition++;
    }

    const whiteBorderStyle = {
        borderWidth: '4px',
        borderStyle: 'solid',
        height: '90px',
        verticalAlign: 'top',
        padding: "12px",
        // maxWidth: "230px",
        minWidth: "226px",
    }
    const yLabelStyle = {
        right: "0",
        position: "absolute",
        //transform: 'rotate(90deg)',
        top: '51%',
        zIndex: "10",
        width: '9.5em',
        textAlign: 'right',
        padding: '0 15px 0 0'
    }

    const shouldHighlight = (currentPosition) => {
        return (currentPosition === matrixPosition)
    }

    return (
        <Row style={{ height: '200px' }}>
            <Col md={6} sm={12} style={{ height: '170px' }}>
                <Row>
                    <Col><div className="report-logo mb-3" /></Col>
                </Row>
                <Row>
                    <Col><h3>candidate <br />profile // <span style={{ fontSize: "1.2rem", marginLeft: "0.4em" }}>{candidateName}</span></h3></Col>
                </Row>
            </Col>
            <Col className="xy-achis personality-matrix" md={6} sm={12}>
            <div style={yLabelStyle}>{label}</div>
                <table><thead><tr><td colSpan={2} className="text-center pb-2">Teamverhalten</td></tr></thead>
                    <tbody className="font-weight-light">
                        <tr>
                            <td style={{ ...whiteBorderStyle, ...{ borderTopWidth: 0, borderLeftWidth: 0 } }} ><div className={`matrix-box ${shouldHighlight(4) && 'highlight'}`}>harmonischer Teamplayer nachgebend</div></td>
                            <td style={{ ...whiteBorderStyle, ...{ borderTopWidth: 0, borderRightWidth: 0 } }} ><div className={`matrix-box ${shouldHighlight(2) && 'highlight'}`}>selbstbewusster Stratege kollaborierend</div></td>
                        </tr>
                        <tr>
                            <td style={{ ...whiteBorderStyle, ...{ borderBottomWidth: 0, borderLeftWidth: 0 } }} ><div className={`matrix-box ${shouldHighlight(3) && 'highlight'}`}>entgegenkommender Kollege vermeidend</div></td>
                            <td style={{ ...whiteBorderStyle, ...{ borderBottomWidth: 0, borderRightWidth: 0 } }} ><div className={`matrix-box ${shouldHighlight(1) && 'highlight'}`}>Einzelkämpfer durchsetzungsstark</div></td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    );
};


XyAxis.defaultProps = {
    minMax: [0, 100],
    value: 0,
    distribution: [],
    label: "Konfliktverhalten"
}

export default XyAxis