import React from 'react';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import { Col, Row } from 'reactstrap';

const FixedScale = (props) => {
    const { minMax, distribution, labelPosition, minMaxLabels } = props
    const [minLabel, maxLabel] = minMaxLabels;
    let [min, max] = minMax;
    let value = props.value || min;
    value = value < min ? min : (value > max ? max : value)
    let labelElement;
    if (props.hasOwnProperty('label')) {
        labelElement = (<Col md={4} xs={12} className={`scaleLabel ${labelPosition === 'right' ? 'text-left pl-0' : 'text-right pr-0'}`}>{props.label}</Col>)
    }

    if (Array.isArray(distribution) && distribution.length !== 0) {
        const segmentMax = (1 / distribution.length) * max;
        let prevValue = 0;
        for (const [distMin, distMax] of distribution) {
            if (distMin <= value && value <= distMax) {
                const currentMax = distMax - distMin;
                const currentPosition = value - distMin;
                const newValue = prevValue + (currentPosition / currentMax * segmentMax)
                console.debug({
                    label: props.label,
                    rawValue: value,
                    prevValue,
                    segmentMax,
                    currentPosition,
                    currentMax,
                    distribution,
                    newValue,
                    min,
                    max
                });
                value = newValue;
                break;
            }
            prevValue += segmentMax;
        }
    }


    const percent = (value - min) / (max - min);
    const marginLeft = (0 <= percent && percent < 0.2) ? `${(1 - percent) * 30}px` : (0.8 < percent && percent <= 2) ? `-${(percent) * 30}px` : 0

    return (
        <Row style={{ marginBottom: "2.7em" }}>
            {labelPosition === "left" ? labelElement : ''}
            <Col md={8} xs={12}>
                <div className="marker-50"/>
                <Slider
                    value={value}
                    min={min}
                    max={max}
                    disabled={true}
                    handleStyle={{
                        cursor: 'default',
                        borderRadius: 0,
                        borderColor: '#333',
                        backgroundColor: '#333',
                        borderWidth: '3px',
                        height: "100%",
                        bottom: '0',
                        marginLeft,
                        width: '60px',
                    }}
                    onAfterChange={(event) => console.log(event)}
                />
                <span className="float-left font-weight-light" style={{ fontSize: '0.8em' }}>{minLabel}</span>
                <span className="float-right font-weight-light" style={{ fontSize: '0.8em' }}>{maxLabel}</span>
            </Col>
            {labelPosition === "right" ? labelElement : ''}
        </Row>
    );
};

FixedScale.defaultProps = {
    minMax: [0, 100],
    labelPosition: 'left',
    value: 0,
    distribution: [],
    minMaxLabels: ['niedrig', 'hoch']
}

export default FixedScale