import React from 'react';

import 'rc-slider/assets/index.css';
import FixedScale from './FixedScale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InfoModal from './InfoModal';
import {Col, Container, Row} from 'reactstrap';
import Loading from './Loading';
import XyAxis from './XyAxis';
import InfoContent from './InfoContent';

const ResultPage = (props) => {
    const [showInfo, setShowInfo] = React.useState(false);
    const {scales, candidateName, isLoading, children} = props;

    if (Object.entries(scales).length === 0) {
        return (
            <Container fluid style={{minHeight: isLoading ? "700px" : "0"}}>
                <Row className="row mb-4 d-print-none">
                    <Col>{!isLoading || <Loading className="text-center"/>}</Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            <Container fluid className="print-page">
                <Row className="row mb-3 result-actions">
                    <Col className="d-flex justify-content-end">
                        <FontAwesomeIcon onClick={() => window.print()} icon="print" size="lg" title="Drucken" className="mr-3 d-print-none hand" fixedWidth={true}/>
                        <FontAwesomeIcon onClick={() => setShowInfo(true)} icon="info-circle" className="d-print-none hand" size="lg" title="Info" fixedWidth={true}/>
                        <InfoModal show={showInfo} onToggle={() => setShowInfo(false)}/>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                        <Row>
                            <Col md={2} sm={4}
                                 className="font-weight-bold ">{scales['Gesprächsstil1']['label']}{scales['Gesprächsstil2']['label']}</Col><Col
                            className="font-weight-light anodina-extra pr-md-5" md={5}
                            sm={8}>{scales['Gesprächsstil1']['value']}, {scales['Gesprächsstil2']['value']}</Col>
                            <Col md={2} sm={4} className="font-weight-bold ">{scales['Motivatoren']['label']}</Col><Col
                            className=" font-weight-light anodina-extra" md={3}
                            sm={8}>{scales['Motivatoren']['value']}</Col>
                        </Row>
                        <Row>
                            <Col md={2} sm={4}
                                 className=" font-weight-bold ">{scales['Kommunikation']['label']}</Col><Col
                            className=" font-weight-light anodina-extra" md={5}
                            sm={8}>{scales['Kommunikation']['value']}</Col>
                            <Col md={2} sm={4} className="font-weight-bold">{scales['Demotivatoren']['label']}</Col><Col
                            className=" font-weight-light anodina-extra" md={3}
                            sm={8}>{scales['Demotivatoren']['value']}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <FixedScale {...scales['Abschlussstärke']} labelPosition={"right"}/>
                        <FixedScale {...scales['Entscheidungsfreude']} labelPosition={"right"}/>
                        <FixedScale {...scales['Veränderungsbereitschaft']} labelPosition={"right"}/>
                        <FixedScale {...scales['Risikobereitschaft']} labelPosition={"right"}/>
                        <FixedScale {...scales['Arbeitsweise']} labelPosition={"right"}
                                    minMaxLabels={['aufgabenorientiert', 'beziehungsorientiert']}/>
                    </Col>
                    <Col md={6} sm={12}>
                        <FixedScale {...scales['Zuverlässigkeit']} labelPosition={"left"}/>
                        <FixedScale {...scales['Konformität']} labelPosition={"left"}/>
                        <FixedScale {...scales['Resilienz']} labelPosition={"left"}/>
                        <FixedScale {...scales['ExtraversionVsIntroversion']} labelPosition={"left"}/>
                    </Col>
                </Row>
                <XyAxis candidateName={candidateName} {...scales['Konfliktverhalten']} />

                {children}
            </Container>
            <Container fluid className="print-page info-page d-print-block d-none">
                <Row>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" size="lg" title="Info" fixedWidth={true}/>
                    </Col>
                </Row>
                <InfoContent/>
            </Container>
        </>
    );
};

export default ResultPage