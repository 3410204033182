import React from "react";
import { Row, Col } from "reactstrap";
import infoConfig from "../assets/info.json";

const InfoContent = () => {

    const renderRow = ([label, description]) => {
        return (
            <Row className="mb-2" key={label}>
                <Col xs={12} md={4} lg={3} className="mb-2 info-label" style={{ fontSize: '1em' }} >{label}</Col>
                <Col xs={12} md={8} lg={9} className="font-weight-light info-desc">{description}</Col>
            </Row>
        )
    }

    return Object.entries(infoConfig).map(renderRow);

};

export default InfoContent;
